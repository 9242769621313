;(function (root, factory) {
  if (typeof exports === 'object' && typeof exports.nodeName !== 'string') {
    factory(exports, require('echarts'))
  } else {
    factory({}, this.echarts)
  }
})(this, function (exports, echarts) {
  let colors = [
    '#144658',
    '#07e6ff',
    '#5ab1ef',
    '#23baa8',
    '#d87a80',
    '#8d98b3',
    '#e5cf0d',
    '#97b552',
    '#95706d',
    '#dc69aa',
    '#07a2a4',
    '#9a7fd1',
    '#588dd5',
    '#f5994e',
    '#c05050',
    '#59678c',
    '#c9ab00',
    '#7eb00a',
    '#6f5553',
    '#c14089',
  ]

  let theme10 = {
    color: colors,

    title: {
      textStyle: {
        fontWeight: 'normal',
        fontSize: 17,
        color: '#008acd',
      },
    },

    dataRange: {
      itemWidth: 15,
      color: ['#5ab1ef', 'red'],
    },

    toolbox: {
      /*
            color : ['#000000', '#1e90ff', '#1e90ff', '#1e90ff'],
            effectiveColor : '#ff4500',
            feature : {
                mark : {
                    title : {
                        mark : 'Markline switch',
                        markUndo : 'Undo markline',
                        markClear : 'Clear markline'
                    }
                }
            }
            */
    },

    animationDuration: 500,

    legend: {
      itemGap: 15,
    },

    tooltip: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      padding: [8, 12, 8, 12],
      axisPointer: {
        type: 'line',
        lineStyle: {
          color: '#607D8B',
          width: 1,
        },
        crossStyle: {
          color: '#607D8B',
        },
        shadowStyle: {
          color: 'rgba(200,200,200,0.2)',
        },
      },
      textStyle: {
        fontFamily: 'Roboto, sans-serif',
      },
    },

    dataZoom: {
      dataBackgroundColor: '#eceff1',
      fillerColor: 'rgba(96,125,139,0.1)',
      handleColor: '#607D8B',
      handleSize: 10,
    },

    grid: {
      borderColor: '#eee',
      containLabel: true,
      top: 40,
      left: 15,
      right: 0,
      bottom: 0,
    },

    categoryAxis: {
      axisLine: {
        lineStyle: {
          show: false,
          color: '#ccc',
          width: 1,
        },
      },
      splitLine: {
        lineStyle: {
          color: ['#eee'],
        },
      },
      nameTextStyle: {
        fontFamily: 'Roboto, sans-serif',
      },
      axisLabel: {
        textStyle: {
          fontFamily: 'Roboto, sans-serif',
        },
      },
    },

    valueAxis: {
      axisLabel: {
        show: false,
        textStyle: {
          fontFamily: 'Roboto, sans-serif',
        },
      },
      axisLine: {
        lineStyle: {
          color: '#ccc',
          width: 1,
        },
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(0,0,0,0.01)', 'rgba(0,0,0,0.0)'],
        },
      },
      splitLine: {
        lineStyle: {
          width: 0,
        },
      },
      nameTextStyle: {
        fontFamily: 'Roboto, sans-serif',
      },
    },

    polar: {
      axisLine: {
        lineStyle: {
          color: '#ddd',
        },
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.2)', 'rgba(200,200,200,0.2)'],
        },
      },
      splitLine: {
        lineStyle: {
          color: '#ddd',
        },
      },
    },

    timeline: {
      lineStyle: {
        color: '#008acd',
      },
      controlStyle: {
        normal: { color: '#008acd' },
        emphasis: { color: '#008acd' },
      },
      symbol: 'emptyCircle',
      symbolSize: 3,
    },

    bar: {
      label: {
        show: false,
      },
      barMaxWidth: 30,
      itemStyle: {
        barBorderRadius: [10, 10, 0, 0],
        shadowBlur: 20,
        shadowColor: 'rgba(0, 0, 0, 0.2)',
      },
    },

    pie: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#fff',
        shadowBlur: 20,
        shadowColor: 'rgba(0, 0, 0, 0.2)',
        emphasis: {
          borderWidth: 0,
          borderColor: '#fff',
        },
      },
    },

    line: {
      smooth: true,
      symbol: 'circle',
      symbolSize: 10,
      label: {
        show: false,
      },
      lineStyle: {
        width: 2,
        shadowColor: 'rgba(0, 0, 0, 0.2)',
        shadowBlur: 20,
      },
      areaStyle: {
        opacity: 0.3,
      },
    },

    k: {
      itemStyle: {
        normal: {
          color: '#d87a80',
          color0: '#2ec7c9',
          lineStyle: {
            color: '#d87a80',
            color0: '#2ec7c9',
          },
        },
      },
    },

    scatter: {
      symbol: 'circle',
      symbolSize: 4,
    },

    radar: {
      symbol: 'circle',
      symbolSize: 5,
    },

    map: {
      label: {
        emphasis: {
          show: true,
          color: colors[0],
        },
      },
      itemStyle: {
        areaColor: '#eee',
        borderColor: '#ccc',
      },
      emphasis: {
        itemStyle: {
          areaColor: 'white',
          shadowColor: 'rgba(0, 0, 0, 0.1)',
          shadowBlur: 20,
        },
        label: {
          color: colors[0],
        },
      },
    },

    force: {
      itemStyle: {
        normal: {
          linkStyle: {
            color: '#1e90ff',
          },
        },
      },
    },

    chord: {
      itemStyle: {
        normal: {
          borderWidth: 1,
          borderColor: 'rgba(128, 128, 128, 0.5)',
          chordStyle: {
            lineStyle: {
              color: 'rgba(128, 128, 128, 0.5)',
            },
          },
        },
        emphasis: {
          borderWidth: 1,
          borderColor: 'rgba(128, 128, 128, 0.5)',
          chordStyle: {
            lineStyle: {
              color: 'rgba(128, 128, 128, 0.5)',
            },
          },
        },
      },
    },

    gauge: {
      axisLine: {
        lineStyle: {
          color: [
            [0.2, '#2ec7c9'],
            [0.8, '#5ab1ef'],
            [1, '#d87a80'],
          ],
          width: 10,
        },
      },
      axisTick: {
        splitNumber: 10,
        length: 15,
        lineStyle: {
          color: 'auto',
        },
      },
      splitLine: {
        length: 22,
        lineStyle: {
          color: 'auto',
        },
      },
      pointer: {
        width: 5,
      },
    },

    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
    },
  }
  echarts.registerTheme('ds', theme10)
})

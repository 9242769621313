export default {
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('./components/views/Login'),
    },
    {
      path: '/telemarketing',
      name: 'telemarketing',
      redirect: { name: 'telemarketing.lobby' },
      component: () => import('./components/layouts/TelemarketingAuthLayout'),
      children: [
        {
          path: '/',
          name: 'telemarketing.lobby',
          component: () => import('./components/views/telemarketing/Lobby'),
        },
        {
          path: 'espera',
          name: 'telemarketing.espera',
          component: () => import('./components/views/telemarketing/Espera'),
        },
        {
          path: 'sessao/:hash',
          name: 'telemarketing.sessao',
          component: () => import('./components/views/telemarketing/Sala'),
          props: true,
        },
      ],
    },
    {
      path: '',
      auth: true,
      component: () => import('./components/layouts/AuthLayout'),
      children: [
        {
          label: 'Alterar Senha',
          description: 'Alterar Senha do Usuário logado na sessão',
          name: 'auth.alterarSenha',
          path: '/alterar-senha',
          icon: 'fa fa-dashboard',
          requireAuth: true,
          component: () =>
            import('./components/views/instituicao/usuarios/AlterarSenha'),
          forceAllow: true,
          menu: false,
        },
        {
          label: 'Dashboard',
          description: 'Dashboard (Home)',
          name: 'dashboard',
          path: '/',
          icon: 'fa fa-dashboard',
          requireAuth: true,
          component: () => import('./components/views/Dashboard'),
        },
        {
          label: 'Instituição',
          description: 'Instituição',
          path: '/instituicao',
          redirect: { name: 'instituicao.detalhe' },
          name: 'instituicao',
          icon: 'fa fa-institution',
        },
        {
          label: 'Visualizar notificações',
          description: 'Visualizar notificações na tela',
          path: '/notificacoes/tela:',
          name: 'notificacoes.tela',
          menu: false,
          requireAuth: true,
          component: false,
        },
        {
          label: 'Informações',
          description: 'Visualizar Informações da Instituição',
          path: '/instituicao/detalhe',
          name: 'instituicao.detalhe',
          icon: 'fa fa-info',
          parent: 'instituicao',
          requireAuth: true,
          component: () => import('./components/views/instituicao/Detalhe'),
        },
        {
          label: 'Configurações',
          description: 'Configurações',
          path: '/instituicao/configuracoes',
          name: 'instituicao.configuracoes',
          icon: 'fa fa-cog',
          parent: 'instituicao',
        },
        {
          label: 'Usuários e Permissões',
          description: 'Visualizar usuário e suas permissões',
          path: '/instituicao/grupos-usuarios',
          name: 'instituicao.usuarios',
          icon: 'fa fa-users',
          parent: 'instituicao.configuracoes',
          redirect: { name: 'instituicao.usuarios.visualizar' },
          requireAuth: true,
          component: () => import('./components/views/instituicao/Usuarios'),
          children: [
            {
              path: '/instituicao/usuarios',
              name: 'instituicao.usuarios.visualizar',
              requireAuth: true,
              component: () =>
                import('./components/views/instituicao/usuarios/Visualizar'),
              props: true,
            },
            {
              path: '/instituicao/grupos',
              name: 'instituicao.grupos.visualizar',
              requireAuth: true,
              component: () =>
                import('./components/views/instituicao/grupos/Visualizar'),
              props: true,
            },
          ],
        },
        {
          label: 'Taxas de Boletos',
          path: '/instituicao/boletos',
          name: 'instituicao.boletos',
          description: 'Configurações de boletos',
          requireAuth: true,
          icon: 'fa fa-barcode',
          parent: 'instituicao.configuracoes',
          component: () =>
            import('./components/views/instituicao/configuracoes/Boleto'),
        },
        {
          path: '/instituicao/grupos/:id(\\d+)/',
          name: 'instituicao.grupos.editar',
          label: 'Editar Grupo',
          description: 'Editar Grupo',
          requireAuth: true,
          parent: 'instituicao.configuracoes',
          component: () =>
            import('./components/views/instituicao/grupos/Editar'),
          props: true,
          menu: false,
        },
        {
          path: ':',
          name: 'instituicao.grupos.deletar',
          label: 'Apagar Grupo',
          description: 'Apagar Grupo',
          requireAuth: true,
          parent: 'instituicao.configuracoes',
          props: true,
          menu: false,
        },
        {
          path: '/instituicao/grupos/novo',
          name: 'instituicao.grupos.novo',
          description: 'Novo grupo',
          label: 'Novo grupo',
          requireAuth: true,
          parent: 'instituicao.configuracoes',
          component: () => import('./components/views/instituicao/grupos/Novo'),
          props: true,
          menu: false,
        },
        {
          label: 'Novo Usuario',
          description: 'Criar Usuário (Acesso)',
          path: '/instituicao/usuarios/novo',
          name: 'instituicao.usuarios.novo',
          requireAuth: true,
          component: () =>
            import('./components/views/instituicao/usuarios/Novo'),
          parent: 'instituicao.configuracoes',
          menu: false,
        },
        {
          label: 'Editar Usuario',
          description: 'Editar Usuário (Acesso)',
          path: '/instituicao/usuarios/:id',
          name: 'instituicao.usuarios.edit',
          requireAuth: true,
          component: () =>
            import('./components/views/instituicao/usuarios/Editar'),
          props: true,
          menu: false,
          parent: 'instituicao.configuracoes',
        },
        {
          label: 'Alterar senha de Usuario',
          description: 'Alterar senha de Usuário (Acesso)',
          path: '/instituicao/usuarios/:id/alterar-senha',
          name: 'instituicao.usuarios.alterarSenha',
          requireAuth: true,
          component: () =>
            import('./components/views/instituicao/usuarios/AlterarSenha'),
          props: true,
          menu: false,
          parent: 'instituicao.configuracoes',
        },
        {
          label: 'Nova Conta Bancária',
          description: 'Criar Contas Bancárias',
          path: '/instituicao/configuracoes/contas/novo',
          name: 'instituicao.configuracoes.contas.novo',
          icon: 'fa fa-bank',
          requireAuth: true,
          component: () =>
            import('./components/views/instituicao/configuracoes/contas/Novo'),
          menu: false,
          parent: 'instituicao.configuracoes',
        },
        {
          label: 'Contas Bancárias',
          description: 'Visualizar Contas Bancárias',
          path: '/instituicao/configuracoes/contas',
          name: 'instituicao.configuracoes.contas.visualizar',
          icon: 'fa fa-bank',
          requireAuth: true,
          component: () =>
            import(
              './components/views/instituicao/configuracoes/contas/Visualizar'
            ),
          parent: 'instituicao.configuracoes',
        },
        {
          label: 'Apagar Contas Bancárias',
          description: 'Apagar Contas Bancárias',
          path: '/instituicao/configuracoes/contas/apagar',
          name: 'instituicao.configuracoes.contas.apagar',
          menu: false,
          component: false,
          parent: 'instituicao.configuracoes',
        },
        {
          label: 'Criar Perfil',
          description: 'Criar Perfis de Colaboradores',
          path: '/instituicao/configuracoes/perfis/novo/doacoes',
          name: 'instituicao.configuracoes.perfis.novo',
          icon: 'fa fa-group',
          requireAuth: true,
          component: () =>
            import('./components/views/instituicao/configuracoes/perfis/Novo'),
          parent: 'instituicao.configuracoes',
          menu: false,
          props: {
            tipo: 'doacoes',
          },
        },
        {
          label: 'Criar Perfil',
          description: 'Criar Perfis de Colaboradores',
          path: '/instituicao/configuracoes/perfis/novo/afiliados',
          name: 'instituicao.configuracoes.perfis.afiliados.novo',
          icon: 'fa fa-group',
          requireAuth: true,
          component: () =>
            import('./components/views/instituicao/configuracoes/perfis/Novo'),
          parent: 'instituicao.configuracoes',
          menu: false,
          props: {
            tipo: 'afiliados',
          },
        },
        {
          label: 'Editar Perfil',
          description: 'Editar Perfis de Colaboradores',
          path: '/instituicao/configuracoes/perfis/:id',
          name: 'instituicao.configuracoes.perfis.edit',
          icon: 'fa fa-group',
          requireAuth: true,
          component: () =>
            import(
              './components/views/instituicao/configuracoes/perfis/Editar'
            ),
          parent: 'instituicao.configuracoes',
          menu: false,
          props: true,
        },
        {
          label: 'Perfis',
          description: 'Visualizar Perfis de Colaboradores',
          path: '/instituicao/configuracoes/perfis',
          name: 'instituicao.configuracoes.perfis.visualizar',
          icon: 'fa fa-group',
          requireAuth: true,
          component: () =>
            import(
              './components/views/instituicao/configuracoes/perfis/Visualizar'
            ),
          parent: 'instituicao.configuracoes',
        },

        {
          label: 'Operadores',
          description: 'Visualizar Operadores',
          path: '/instituicao/operadores',
          name: 'instituicao.operadores.visualizar',
          icon: 'fa fa-user-secret',
          parent: 'instituicao.configuracoes',
          requireAuth: true,
          component: () =>
            import('./components/views/instituicao/operadores/Visualizar'),
        },
        {
          label: 'Novo Operador',
          description: 'Criar Operadores',
          path: '/instituicao/operadores/novo',
          name: 'instituicao.operadores.novo',
          requireAuth: true,
          component: () =>
            import('./components/views/instituicao/operadores/Novo'),
          props: true,
          menu: false,
          parent: 'instituicao.configuracoes',
        },
        {
          label: 'Editar Operadores',
          description: 'Editar Operadores',
          path: '/instituicao/operadores/:id',
          name: 'instituicao.operadores.edit',
          requireAuth: true,
          component: () =>
            import('./components/views/instituicao/operadores/Editar'),
          props: true,
          menu: false,
          parent: 'instituicao.configuracoes',
        },

        {
          label: 'Automações',
          description: 'Configurações de automações',
          path: '/instituicao/automacoes',
          name: 'instituicao.automacoes',
          icon: 'fa fa-magic',
          parent: 'instituicao.configuracoes',
          component: () =>
            import(
              './components/views/instituicao/configuracoes/automacoes/Visualizar'
            ),
        },
        {
          label: 'Conexões',
          description: 'Conexões',
          path: '/configuracoes/conexoes::',
          name: 'configuracoes.conexoes',
          parent: 'instituicao.configuracoes',
          icon: 'fa fa-link',
        },
        {
          label: 'WhatsApp',
          description: 'Visualizar Conexões do WhatsApp',
          path: '/configuracoes/conexoes/whatsapp',
          name: 'configuracoes.conexoes.whatsapp.visualizar',
          parent: 'configuracoes.conexoes',
          icon: 'fa fa-whatsapp',
          component: () =>
            import(
              './components/views/instituicao/configuracoes/conexoes/whatsapp/Visualizar'
            ),
        },
        {
          label: 'WhatsApp',
          description: 'Criar Conexão do WhatsApp',
          path: '/configuracoes/conexoes/whatsapp/novo',
          parent: 'configuracoes.conexoes.whatsapp',
          name: 'configuracoes.conexoes.whatsapp.novo',
          icon: 'fa fa-whatsapp',
          component: () =>
            import(
              './components/views/instituicao/configuracoes/conexoes/whatsapp/Novo'
            ),
        },
        {
          label: 'Páginas',
          description: 'Páginas',
          path: '/paginas',
          name: 'paginas',
          icon: 'fa fa-chrome',
          parent: 'instituicao.configuracoes',
        },
        {
          label: 'Checkout',
          description: 'Checkout',
          path: '/paginas/checkout',
          name: 'paginas.checkout',
          icon: 'fa fa-shopping-cart',
          parent: 'paginas',
          component: () =>
            import(
              './components/views/instituicao/configuracoes/paginas/Checkout'
            ),
        },
        {
          label: 'Área do doador',
          description: 'Área do doador',
          path: '/paginas/area-doador',
          name: 'paginas.area.doador',
          icon: 'fa fa-heart',
          parent: 'paginas',
          component: () =>
            import(
              './components/views/instituicao/configuracoes/paginas/area-doador/Visualizar'
            ),
        },
        {
          label: 'Editar doador',
          description: 'Editar doador',
          path: '/paginas/area-doador/:id(\\d+)',
          name: 'paginas.area.doador.editar',
          icon: 'fa fa-shopping-cart',
          parent: 'paginas',
          props: true,
          component: () =>
            import(
              './components/views/instituicao/configuracoes/paginas/area-doador/Editar'
            ),
          menu: false,
        },
        {
          label: 'Nova área do doador',
          description: 'Nova area do doador',
          path: '/paginas/area-doador/novo',
          name: 'paginas.area.doador.novo',
          icon: 'fa fa-shopping-cart',
          parent: 'paginas',
          component: () =>
            import(
              './components/views/instituicao/configuracoes/paginas/area-doador/Novo'
            ),
          menu: false,
        },
        // {
        //    label: 'Página de Captação',
        //    description: 'Página de Captação',
        //    path: '/paginas/pagina-captacao/visualizar',
        //    name: 'paginas.captacao.visualizar',
        //    icon: 'fa fa-handshake-o',
        //    parent: 'paginas',
        //    component: () => import('./components/views/instituicao/configuracoes/paginas/pagina-captacao/Visualizar'),
        // },
        // {
        //    label: 'Página de Captação',
        //    description: 'Nova Página de Captação',
        //    path: '/paginas/pagina-captacao/novo',
        //    name: 'paginas.pagina.captacao.novo',
        //    icon: 'fa fa-handshake-o',
        //    parent: 'paginas',
        //    menu: false,
        //    component: () => import('./components/views/instituicao/configuracoes/paginas/pagina-captacao/Novo'),
        // },
        // {
        //    label: 'Editar Página',
        //    description: 'Editar Página',
        //    path: '/paginas/pagina-captacao/:id(\\d+)',
        //    name: 'paginas.pagina.captacao.editar',
        //    icon: 'fa fa-shopping-cart',
        //    parent: 'paginas',
        //    props:true,
        //    component: () => import('./components/views/instituicao/configuracoes/paginas/pagina-captacao/Novo'),
        //    menu: false
        // },

        // Relatórios
        {
          label: 'Relatórios',
          description: 'Relatórios',
          path: '/relatorios',
          name: 'relatorios',
          icon: 'fa fa-bar-chart',
        },
        {
          label: 'Alterar Perfil Pagamentos',
          description: 'Alterar Perfil Pagamentos',
          name: 'relatorios.alterar_perfil',
          path: '/relatorios/alterar_perfil:',
          icon: '',
          parent: 'relatorios',
          menu: false,
          component: false,
        },
        {
          label: 'Cancelar Pagamentos Manuais',
          description: 'Cancelar Pagamentos Manuais',
          name: 'relatorios.cancelar.pagamento_manual',
          path: '/relatorios/cancelar_pagamento_manual:',
          icon: '',
          parent: 'relatorios',
          menu: false,
          component: false,
        },
        {
          label: 'Colaboradores',
          description: 'Visualizar Relatórios de Colaboradores',
          parent: 'relatorios',
          name: 'relatorios.colaboradores',
          path: '/relatorios/colaboradores',
          redirect: { name: 'relatorios.colaboradores.geral' },
          icon: 'fa fa-users',
          requireAuth: true,
          component: () =>
            import('./components/views/relatorios/Colaboradores'),
          children: [
            {
              name: 'relatorios.colaboradores.geral',
              path: 'geral',
              requireAuth: true,
              component: () =>
                import('./components/views/relatorios/colaboradores/Geral'),
            },
            {
              name: 'relatorios.colaboradores.inativos',
              path: 'inativos',
              requireAuth: true,
              component: () =>
                import('./components/views/relatorios/colaboradores/Inativos'),
            },
            {
              name: 'relatorios.colaboradores.aniversariantes',
              path: 'aniversariantes',
              requireAuth: true,
              component: () =>
                import(
                  './components/views/relatorios/colaboradores/Aniversariantes'
                ),
            },
            {
              name: 'relatorios.colaboradores.regioes',
              path: 'regioes',
              requireAuth: true,
              component: () =>
                import('./components/views/relatorios/colaboradores/Regioes'),
            },
            {
              name: 'relatorios.colaboradores.semdoacao',
              path: 'semdoacao',
              requireAuth: true,
              component: () =>
                import('./components/views/relatorios/colaboradores/SemDoacao'),
            },
          ],
        },
        {
          label: 'Baixar relatórios',
          description: 'Permitir baixar relatórios de colaboradores',
          menu: false,
          name: 'relatorios.colaboradores.download',
          path: '/relatorios/colaboradores/download:',
          component: false,
          //  parent:'relatorios.colaboradores'
        },

        {
          label: 'Faturas',
          description: 'Relatório de Faturas de Colaboradores',
          name: 'faturas',
          icon: 'fa fa-file-o',
          parent: 'relatorios',
          path: '/relatorios/faturas',
          requireAuth: true,
          component: () => import('./components/views/relatorios/Faturas'),
        },

        {
          label: 'Doações',
          description: 'Relatório de Doações de Colaboradores',
          name: 'doacoes',
          icon: 'fa fa-heart-o',
          parent: 'relatorios',
          path: '/relatorios/pagamentos/doacoes',
          props: { grupo: 'colaboradores' },
          requireAuth: true,
          component: () => import('./components/views/relatorios/Pagamentos'),
        },

        {
          label: 'Baixar relatórios de pagamentos',
          description: 'Permitir baixar relatórios de pagamentos',
          menu: false,
          name: 'relatorios.pagamentos.download',
          path: '/relatorios/pagamentos/download:',
          component: false,
          // parent:'relatorios.colaboradores'
        },

        {
          label: 'Cupons',
          description: 'Relatório de cupons',
          name: 'cupons',
          icon: 'fa fa-bookmark-o',
          parent: 'relatorios',
          path: '/relatorios/cupons',
          requireAuth: true,
          component: () => import('./components/views/relatorios/Cupons'),
        },

        {
          label: 'Eventos',
          description: 'Relatório de eventos',
          name: 'relatorio.eventos',
          icon: 'fa fa-calendar-check-o',
          parent: 'relatorios',
          path: '/relatorios/pagamentos/eventos',
          props: { grupo: 'eventos' },
          requireAuth: true,
          component: () => import('./components/views/relatorios/Pagamentos'),
        },
        {
          label: 'Campanhas',
          description: 'Relatório de campanhas',
          name: 'relatorio.campanhas',
          icon: 'fa fa-bullhorn',
          parent: 'relatorios',
          path: '/relatorios/pagamentos/campanhas',
          props: { grupo: 'campanhas' },
          requireAuth: true,
          component: () => import('./components/views/relatorios/Pagamentos'),
        },
        {
          label: 'Vendas',
          description: 'Relatório de vendas de produtos',
          name: 'vendas',
          icon: 'fa fa-cart-arrow-down',
          parent: 'relatorios',
          path: '/relatorios/vendas',
          requireAuth: true,
          permissions: {
            key: 'loja',
            description: 'Utiliza Loja',
          },
          component: () => import('./components/views/relatorios/Vendas'),
        },

        {
          label: 'Baixar relatórios vendas',
          description: 'Permitir baixar relatórios de vendas',
          menu: false,
          name: 'relatorios.vendas.download',
          path: '/relatorios/vendas/download:',
          component: false,
          // parent:'vendas'
        },
        {
          label: 'Comerciantes',
          description: 'Relatório de Pagamentos de Comerciantes',
          name: 'pagamentos',
          icon: 'fa fa-handshake-o',
          parent: 'relatorios',
          path: '/relatorios/pagamentos/afiliados',
          props: { grupo: 'afiliados' },
          requireAuth: true,
          component: () => import('./components/views/relatorios/Pagamentos'),
          permissions: {
            key: 'relatorios.afiliados',
            description: 'Utiliza Afiliados',
          },
        },
        {
          label: 'Notificações',
          description: 'Relatório de Notificações',
          name: 'notificacoes',
          icon: 'fa fa-flag-o',
          parent: 'relatorios',
          path: '/relatorios/notificacoes',
          redirect: { name: 'relatorios.notificacoes.email' },
          requireAuth: true,
          component: () => import('./components/views/relatorios/Notificacoes'),
          children: [
            {
              label: 'Email',
              description: 'Visualizar Relatórios Notificações de Email',
              name: 'relatorios.notificacoes.email',
              path: 'email',
              icon: 'fa fa-at',
              requireAuth: true,
              component: () =>
                import('./components/views/relatorios/notificacoes/Email'),
            },
          ],
        },

        {
          label: 'Baixar relatórios de notificações',
          description: 'Permitir baixar relatórios de notificações',
          menu: false,
          name: 'relatorios.notificacoes.download',
          path: '/relatorios/notificacoes/download:',
          component: false,
          // parent:'vendas'
        },
        {
          label: 'Pendências',
          description: 'Pendências',
          path: '/pendencias:',
          name: 'pendencias',
          parent: 'relatorios',
          icon: 'fa fa-clock-o',
        },

        {
          label: 'Baixar relatórios de pendencias',
          description: 'Permitir baixar relatórios de pendencias',
          menu: false,
          name: 'relatorios.pendencias.download',
          path: '/relatorios/pendencias/download:',
          component: false,
          // parent:'vendas'
        },
        {
          label: 'Doações',
          description: 'Relatório de Doações Pendêntes de Colaboradores',
          parent: 'pendencias',
          name: 'doacoes-pendentes',
          path: '/relatorios/pendencias/doacoes',
          icon: 'fa fa-heart-o',
          props: { grupo: 'colaboradores' },
          requireAuth: true,
          component: () => import('./components/views/relatorios/Pendencias'),
        },
        {
          label: 'Comerciantes',
          description: 'Relatório de Doações Pendêntes de Comerciantes',
          parent: 'pendencias',
          name: 'comerciantes-pendentes',
          path: '/relatorios/pendencias/comerciantes',
          icon: 'fa fa-handshake-o',
          props: { grupo: 'afiliados' },
          requireAuth: true,
          component: () => import('./components/views/relatorios/Pendencias'),
        },
        {
          label: 'Eventos',
          description: 'Relatório Pendencias de eventos',
          parent: 'pendencias',
          name: 'eventos-pendentes',
          path: '/relatorios/pendencias/eventos',
          icon: 'fa fa-calendar-check-o',
          props: { grupo: 'eventos' },
          requireAuth: true,
          component: () => import('./components/views/relatorios/Pendencias'),
        },
        {
          label: 'Campanhas',
          description: 'Relatório Pendencias de campanhas',
          parent: 'pendencias',
          name: 'campanhas-pendentes',
          path: '/relatorios/pendencias/campanhas',
          icon: 'fa fa-bullhorn',
          props: { grupo: 'campanhas' },
          requireAuth: true,
          component: () => import('./components/views/relatorios/Pendencias'),
        },

        /*{
                    label: 'Financeiro',
                    description: 'Relatório de Financeiro',
                    name: 'relatorio.financeiro',
                    path: '/relatorios/financeiro',
                    icon: 'fa fa-dollar',
                    props: false,
                    parent:'relatorios',
                    requireAuth: true,
                    component: () => import('./components/views/relatorios/financeiro/Visualizar'),
                },*/
        {
          label: 'Operadores',
          description: 'Visualizar Relatórios de Operadores',
          parent: 'relatorios',
          name: 'relatorios.operadores',
          path: '/relatorios/operadores',
          icon: 'fa fa-user-secret',
          redirect: { name: 'relatorios.operadores.cadastros' },
          requireAuth: true,
          props: true,
          component: () => import('./components/views/relatorios/Operadores'),
          children: [
            {
              label: 'Cadastro de Operadores',
              description: 'Visualizar Relatórios de Operadores',
              parent: 'relatorios',
              name: 'relatorios.operadores.cadastros',
              path: 'cadastros',
              icon: 'fa fa-user-secret',
              requireAuth: true,
              props: true,
              component: () =>
                import('./components/views/relatorios/operadores/Cadastros'),
            },
          ],
        },

        // {
        //     label: 'Gerais',
        //     description: 'Visualizar Relatórios de Doações Gerais',
        //     parent: 'doacoes',
        //     name: 'relatorios.doacoes',
        //     path: '/relatorios/doacoes/:tipo_pagamento?',
        //     icon: 'fa fa-asterisk',
        //     props: true,
        //     requireAuth: true,
        //     component: () => import('./components/views/relatorios/Pagamentos'),
        // },
        // {
        //     label: 'Por Região',
        //     description: 'Visualizar Relatórios de Doações por Região',
        //     parent: 'doacoes',
        //     name: 'relatorios.doacoes.regiao',
        //     path: '/relatorios/doacoes/regiao',
        //     icon: 'fa fa-map-marker',
        //     requireAuth: true,
        //     component: () => import('./components/views/relatorios/DoacoesRegiao'),
        // },

        /*
                {
                    label: 'Boletos',
                    description: 'Visualizar Relatórios de Boletos',
                    parent: 'relatorios',
                    name: 'relatorios.boletos',
                    path: '/relatorios/boletos',
                    icon: 'fa fa-barcode',
                    requireAuth: true,
                    component: () => import('./components/views/relatorios/Boletos'),
                },

                {
                    label: 'Aparelhos',
                    description: 'Visualizar Relatórios de Aparelhos',
                    parent: 'relatorios',
                    name: 'relatorios.aparelhos',
                    path: '/relatorios/aparelhos',
                    redirect: {name: 'relatorios.aparelhos.dsTouch'},
                    icon: 'fa fa-tablet',
                    requireAuth: true,
                    component: () => import('./components/views/relatorios/Aparelhos'),
                    children: [
                        {
                            name: 'relatorios.aparelhos.dsTouch',
                            path: 'ds-touch',
                            requireAuth: true,
                            component: () => import('./components/views/relatorios/aparelhos/DsTouch'),
                        },
                    ]
                },
                */
        /*
                {
                    label: 'Notificações',
                    description: 'Visualizar Relatórios de Notificações de Email',
                    parent: 'relatorios',
                    name: 'relatorios.notificacoes',
                    path: '/relatorios/notificacoes',
                    redirect: {name: 'relatorios.notificacoes.email'},
                    icon: 'fa fa-bell',
                    requireAuth: true,
                    component: () => import('./components/views/relatorios/Notificacoes'),
                    children: [
                        {
                            label: 'Email',
                            description: 'Visualizar Relatórios Notificações de Email',
                            name: 'relatorios.notificacoes.email',
                            path: 'email',
                            icon: 'fa fa-at',
                            requireAuth: true,
                            component: () => import('./components/views/relatorios/notificacoes/Email'),
                        },
                    ]
                },
    */
        {
          label: 'Colaborador',
          description: 'Visualizar Relatório de Colaborador',
          parent: 'relatorios',
          name: 'relatorios.colaborador',
          path: '/relatorios/colaboradores/:id',
          icon: 'fa fa-user',
          requireAuth: true,
          component: () => import('./components/views/relatorios/Colaborador'),
          props: true,
          menu: false,
          children: [
            {
              path: 'doacoes',
              name: 'relatorios.colaborador.doacoes',
              requireAuth: true,
              component: () =>
                import('./components/views/relatorios/colaboradores/Doacoes'),
              props: true,
            },
            {
              path: 'pendencias',
              name: 'relatorios.colaborador.pendencias',
              requireAuth: true,
              component: () =>
                import(
                  './components/views/relatorios/colaboradores/Pendencias'
                ),
              props: true,
            },
            {
              path: 'informacoes',
              name: 'relatorios.colaborador.informacoes',
              requireAuth: true,
              component: () =>
                import(
                  './components/views/relatorios/colaboradores/Informacoes'
                ),
              props: true,
            },
            {
              path: 'historico',
              name: 'relatorios.colaborador.historico',
              requireAuth: true,
              component: () =>
                import('./components/views/relatorios/colaboradores/Historico'),
              props: true,
            },
            {
              path: 'documento',
              name: 'relatorios.colaborador.documento',
              requireAuth: true,
              component: () =>
                import('./components/views/relatorios/colaboradores/Documento'),
              props: true,
            },
          ],
        },

        // Colaboradores
        {
          label: 'Colaboradores',
          description: 'Colaboradores',
          path: '/colaboradores::',
          name: 'colaboradores',
          icon: 'fa fa-users',
        },
        {
          label: 'Oferta',
          description: 'Oferta',
          path: '/colaborador/oferta',
          name: 'colaborador.oferta',
          icon: 'fa fa-gratipay',
          requireAuth: true,
          component: () => import('./components/views/colaboradores/Oferta'),
        },
        {
          label: 'Perfil',
          description: 'Perfil Colaborador',
          parent: 'colaboradores',
          name: 'colaboradores.perfil',
          path: '/colaboradores/:id/perfil',
          icon: 'fa fa-plus',
          requireAuth: true,
          component: () => import('./components/views/colaboradores/Perfil'),
          props: true,
          menu: false,
        },
        {
          label: 'Novo',
          description: 'Criar Novo colaborador',
          parent: 'colaboradores',
          name: 'colaboradores.novo',
          path: '/colaboradores/novo',
          icon: 'fa fa-plus',
          requireAuth: true,
          component: () => import('./components/views/colaboradores/Novo'),
        },
        {
          label: 'Editar',
          description: 'Editar Colaborador',
          parent: 'colaboradores',
          name: 'colaboradores.edit',
          path: '/colaboradores/:id',
          icon: 'fa fa-plus',
          requireAuth: true,
          component: () => import('./components/views/colaboradores/Editar'),
          props: true,
          menu: false,
        },
        {
          label: 'Ativar Colaborador',
          description: 'Ativar Colaborador',
          parent: 'colaboradores',
          name: 'colaboradores.ativar',
          path: '/colaboradores/:id/ativar',
          icon: 'fa fa-check',
          requireAuth: true,
          component: () => import('./components/views/colaboradores/Ativar'),
          props: true,
          menu: false,
        },
        {
          label: 'Inativar Colaborador',
          description: 'Inativar Colaborador',
          parent: 'colaboradores',
          name: 'colaboradores.inativar',
          path: '/colaboradores/:id/inativar',
          icon: 'fa fa-ban',
          requireAuth: true,
          component: () => import('./components/views/colaboradores/Inativar'),
          props: true,
          menu: false,
        },
        {
          label: 'Visualizar',
          description: 'Visualizar Colaboradores',
          parent: 'colaboradores',
          path: '/colaboradores',
          name: 'colaboradores.visualizar',
          icon: 'fa fa-bars',
          requireAuth: true,
          component: () =>
            import('./components/views/colaboradores/Visualizar'),
        },
        {
          label: 'Comerciantes',
          description: 'Comerciantes',
          path: '/afiliados_',
          name: 'afiliados',
          icon: 'fa fa-cart-plus',
          permissions: {
            key: 'afiliados',
            description: 'Utiliza Afiliados',
          },
        },
        // Financeiro
        {
          label: 'Financeiro',
          description: 'Financeiro',
          path: '/financeiro',
          name: 'financeiro',
          icon: 'fa fa-calculator',
        },

        {
          label: 'Extrato',
          description: 'Extrado de contas',
          parent: 'financeiro',
          name: 'financeiro.contas.extrato',
          path: '/financeiro/contas/extrato',
          icon: 'fa fa-line-chart',
          component: () =>
            import('./components/views/financeiro/contas/Extrato'),
        },
        {
          label: 'Portadores',
          description: 'Portadores',
          parent: 'financeiro',
          name: 'financeiro.portadores',
          path: '/financeiro/portadores:',
          icon: 'fa fa-university',
        },
        {
          label: 'Novo',
          description: 'Novo',
          parent: 'financeiro.portadores',
          name: 'financeiro.portadores.novo',
          path: '/financeiro/portadores/novo',
          icon: 'fa fa-plus',
          requireAuth: true,
          component: () =>
            import('./components/views/financeiro/contas/portadores/Novo'),
        },
        {
          label: 'Portador',
          description: 'Portador',
          name: 'financeiro.portadores.portador',
          path: '/financeiro/portadores/:id',
          icon: 'fa fa-plus',
          requireAuth: true,
          component: () =>
            import('./components/views/financeiro/contas/portadores/Portador'),
          menu: false,
          redirect: { name: 'financeiro.portadores.editar' },
          children: [
            {
              name: 'financeiro.portadores.editar',
              path: 'editar',
              props: true,
              component: () =>
                import(
                  './components/views/financeiro/contas/portadores/Editar'
                ),
            },
            {
              name: 'financeiro.portadores.integracao',
              path: 'integracao',
              props: true,
              component: () =>
                import(
                  './components/views/financeiro/contas/portadores/Integracao'
                ),
            },
            {
              name: 'financeiro.portadores.conciliacao',
              path: 'conciliacao',
              props: true,
              component: () =>
                import(
                  './components/views/financeiro/contas/portadores/Conciliacao'
                ),
            },
          ],
        },
        {
          label: 'Visualizar',
          description: 'Visualizar',
          parent: 'financeiro.portadores',
          name: 'financeiro.portadores.visualizar',
          path: '/financeiro/portadores',
          icon: 'fa fa-list',
          requireAuth: true,
          component: () =>
            import(
              './components/views/financeiro/contas/portadores/Visualizar'
            ),
        },
        // {
        //     label: 'Contas',
        //     description: 'Contas',
        //     parent: 'financeiro',
        //     name: 'financeiro.contas',
        //     path: '/financeiro/contas:',
        //     icon: 'fa fa-money'
        // },

        {
          label: 'Contas a Pagar',
          description: 'Titulos a Pagar',
          parent: 'financeiro',
          name: 'financeiro.contas.pagar.titulos',
          path: '/financeiro/contas/pagar/titulos:',
          //component: () => import('./components/views/financeiro/contas/TitulosPagar'),
          icon: 'fa fa-arrow-circle-down',
        },
        {
          label: 'Novo',
          description: 'Novo Titulo a Pagar',
          parent: 'financeiro.contas.pagar.titulos',
          icon: 'fa fa-plus',
          name: 'financeiro.contas.pagar.titulos.novo',
          path: '/financeiro/contas/pagar/titulos/novo',
          props: {
            tipo: 'pagar',
          },
          component: () =>
            import('./components/views/financeiro/contas/titulos/Pagar'),
        },
        {
          label: 'Visualizar',
          description: 'Novo Titulo a Pagar',
          parent: 'financeiro.contas.pagar.titulos',
          icon: 'fa fa-list',
          name: 'financeiro.contas.pagar.titulos.visualizar',
          path: '/financeiro/contas/pagar/titulos',
          props: {
            tipo: 'pagar',
          },
          component: () =>
            import('./components/views/financeiro/contas/titulos/Visualizar'),
        },
        {
          label: 'Editar',
          description: 'Editar Titulos',
          parent: 'financeiro',
          icon: 'fa fa-edit',
          name: 'financeiro.contas.titulos.editar',
          path: '/financeiro/contas/titulos/:id(\\d+)',
          menu: false,
          component: () =>
            import('./components/views/financeiro/contas/titulos/Editar'),
        },
        {
          label: 'Baixar',
          description: 'Baixar Titulos',
          parent: 'financeiro',
          name: 'financeiro.contas.titulos.baixar',
          path: '/financeiro/contas/titulos/baixar:',
          menu: false,
          component: false,
        },
        //   {
        //       label: 'Cancelar',
        //       description: 'Cancelar Titulo',
        //       parent: 'financeiro',
        //       name: 'financeiro.contas.titulos.cancelar',
        //       path: '/financeiro/contas/titulos/cancelar:',
        //       menu:false,
        //       component: false,
        //   },
        {
          label: 'Editar Recorrência',
          description: 'Editar Recorrência de Titulos',
          icon: 'fa fa-edit',
          name: 'financeiro.contas.titulos.recorrencias.editar',
          path: '/financeiro/contas/titulos/recorrencias/:id(\\d+)',
          menu: false,
          component: () =>
            import('./components/views/financeiro/contas/recorrencias/Editar'),
        },
        {
          label: 'Contas a Receber',
          description: 'Titulos a Receber',
          parent: 'financeiro',
          name: 'financeiro.contas.receber.titulos',
          path: '/financeiro/contas/receber/titulos:',
          //component: () => import('./components/views/financeiro/contas/TitulosPagar'),
          icon: 'fa fa-arrow-circle-up',
        },
        {
          label: 'Novo',
          description: 'Novo Titulo a Receber',
          parent: 'financeiro.contas.receber.titulos',
          icon: 'fa fa-plus',
          name: 'financeiro.contas.receber.titulos.novo',
          path: '/financeiro/contas/receber/titulos/novo',
          component: () =>
            import('./components/views/financeiro/contas/titulos/Receber'),
          props: {
            tipo: 'receber',
          },
        },
        {
          label: 'Visualizar',
          description: 'Novo Titulo a Receber',
          parent: 'financeiro.contas.receber.titulos',
          icon: 'fa fa-list',
          name: 'financeiro.contas.receber.titulos.visualizar',
          path: '/financeiro/contas/receber/titulos',
          props: {
            tipo: 'receber',
          },
          component: () =>
            import('./components/views/financeiro/contas/titulos/Visualizar'),
        },

        {
          label: 'Transferências',
          description: 'Transferências',
          parent: 'financeiro',
          name: 'financeiro.contas.transferencias',
          redirect: { name: 'financeiro.contas.transferencias.nova' },
          path: '/financeiro/contas/transferencias/',
          component: () =>
            import('./components/views/financeiro/contas/Transferencia'),
          icon: 'fa fa-exchange',
          children: [
            {
              path: 'nova',
              name: 'financeiro.contas.transferencias.nova',
              requireAuth: true,
              component: () =>
                import(
                  './components/views/financeiro/contas/transferencia/Nova'
                ),
              props: true,
            },
            {
              path: 'efetuadas',
              name: 'financeiro.contas.transferencias.efetuadas',
              requireAuth: true,
              component: () =>
                import(
                  './components/views/financeiro/contas/transferencia/Efetuadas'
                ),
              props: true,
            },
          ],
        },

        {
          label: 'Contas Recorrentes',
          description: 'Recorrencias',
          parent: 'financeiro',
          icon: 'fa fa-refresh',
          name: 'financeiro.contas.pagar.titulos.recorrencias',
          path: '/financeiro/contas/pagar/titulos/recorrencias',
          component: () =>
            import(
              './components/views/financeiro/contas/recorrencias/Visualizar'
            ),
        },

        {
          label: 'Fornecedores',
          description: 'Fornecedores',
          parent: 'financeiro',
          name: 'financeiro.contas.fornecedores',
          path: '/financeiro/contas/fornecedores:',
          //component: () => import('./components/views/financeiro/contas/TitulosPagar'),
          icon: 'fa fa-handshake-o',
        },

        // Financeiro / Transferências

        /*{
                    label: 'Transferências DS',
                    description: 'Transferências',
                    parent: 'financeiro',
                    name: 'financeiro.transferencias',
                    path: '/financeiro/transferencias_',
                    icon: 'fa fa-exchange',
                    menu:false,
                },*/
        {
          label: 'Novo',
          description: 'Novo Fornecedor',
          parent: 'financeiro.contas.fornecedores',
          icon: 'fa fa-plus',
          name: 'financeiro.contas.fornecedores.novo',
          path: '/financeiro/contas/fornecedores/novo',
          component: () =>
            import('./components/views/financeiro/contas/fornecedores/Novo'),
        },
        {
          label: 'Editar',
          description: 'Novo Fornecedor',
          parent: 'financeiro.contas.fornecedores',
          icon: 'fa fa-plus',
          name: 'financeiro.contas.fornecedores.editar',
          path: '/financeiro/contas/fornecedores/:id(\\d+)',
          menu: false,
          component: () =>
            import('./components/views/financeiro/contas/fornecedores/Editar'),
        },
        {
          label: 'Visualizar',
          description: 'Visualizar Fornecedores',
          parent: 'financeiro.contas.fornecedores',
          icon: 'fa fa-list',
          name: 'financeiro.contas.fornecedores.visualizar',
          path: '/financeiro/contas/fornecedores',
          component: () =>
            import(
              './components/views/financeiro/contas/fornecedores/Visualizar'
            ),
        },
        {
          label: 'Extrato DS',
          description: 'Visualizar Extrato de Movimentação',
          parent: 'financeiro',
          name: 'financeiro.extrato.visualizar',
          path: '/financeiro/extrato',
          icon: 'fa fa-list',
          requireAuth: true,
          menu: false,
          component: () =>
            import('./components/views/financeiro/VisualizarExtrato'),
        },
        {
          label: 'Nova Transferência',
          description: 'Realizar Transferências',
          parent: 'financeiro.transferencias',
          name: 'financeiro.transferencias.novo',
          path: '/financeiro/transferencias/novo',
          icon: 'fa fa-plus',
          requireAuth: true,
          component: () =>
            import('./components/views/financeiro/NovaTransferencia'),
          permissions: {
            key: 'financeiro.transferencia',
            description: 'Transferência',
          },
        },
        {
          label: 'Visualizar',
          description: 'Visualizar Transferências',
          parent: 'financeiro.transferencias',
          name: 'financeiro.transferencias.visualizar',
          path: '/financeiro/transferencias',
          icon: 'fa fa-bars',
          requireAuth: true,
          component: () =>
            import('./components/views/financeiro/VisualizarTransferencias'),
        },
        {
          label: 'Loja',
          description: 'Loja',
          path: '/loja_',
          name: 'loja',
          icon: 'fa fa-shopping-basket',
          permissions: {
            key: 'loja',
            description: 'Utiliza Loja',
          },
        },
        {
          label: 'Novo Produto',
          description: 'Novo Produto',
          parent: 'loja',
          path: '/loja/produtos/novo',
          name: 'loja.produtos.novo',
          icon: 'fa fa-plus',
          requireAuth: true,
          component: () => import('./components/views/loja/produtos/Novo'),
          permissions: {
            key: 'loja',
            description: 'Utiliza Loja',
          },
        },
        {
          label: 'Editar Produto',
          description: 'Editar Produto',
          parent: 'loja',
          path: '/loja/produtos/:id',
          name: 'loja.produtos.editar',
          icon: 'fa fa-pincel',
          requireAuth: true,
          component: () => import('./components/views/loja/produtos/Editar'),
          permissions: {
            key: 'loja',
            description: 'Utiliza Loja',
          },
          menu: false,
        },
        {
          label: 'Produtos',
          description: 'Produtos',
          parent: 'loja',
          path: '/loja/produtos',
          name: 'loja.produtos',
          icon: 'fa fa-list',
          requireAuth: true,
          component: () =>
            import('./components/views/loja/produtos/visualizar'),
          permissions: {
            key: 'loja',
            description: 'Utiliza Loja',
          },
        },
        {
          label: 'Categorias',
          description: 'Categorias',
          parent: 'loja',
          path: '/loja/categorias:',
          name: 'loja.categorias',
          icon: 'fa fa-object-group',
          requireAuth: true,
          permissions: {
            key: 'loja',
            description: 'Utiliza Loja',
          },
        },
        {
          label: 'Nova',
          description: 'Nova categoria',
          parent: 'loja.categorias',
          name: 'loja.categorias.novo',
          icon: 'fa fa-plus',
          path: '/loja/categorias/novo',
          component: () => import('./components/views/loja/categorias/Novo'),
          permissions: {
            key: 'loja',
            description: 'Utiliza Loja',
          },
        },
        {
          label: 'Editar',
          description: 'Editar categoria',
          parent: 'loja.categorias',
          name: 'loja.categorias.editar',
          icon: 'fa fa-plus',
          path: '/loja/categorias/:categoria_id(\\d)',
          component: () => import('./components/views/loja/categorias/Editar'),
          menu: false,
          permissions: {
            key: 'loja',
            description: 'Utiliza Loja',
          },
          props: true,
        },
        {
          label: 'Visualizar',
          description: 'Visualizar categoria',
          parent: 'loja.categorias',
          name: 'loja.categorias.visualizar',
          icon: 'fa fa-list',
          path: '/loja/categorias',
          component: () =>
            import('./components/views/loja/categorias/Visualizar'),
          permissions: {
            key: 'loja',
            description: 'Utiliza Loja',
          },
        },
        {
          label: 'Ativar Comerciante',
          description: 'Ativar Comerciante',
          parent: 'afiliados',
          name: 'afiliados.ativar',
          path: '/afiliados/:id/ativar',
          icon: 'fa fa-check',
          requireAuth: true,
          component: () => import('./components/views/afiliados/Ativar'),
          props: true,
          menu: false,
        },
        {
          label: 'Inativar Comerciante',
          description: 'Inativar Comerciante',
          parent: 'afiliados',
          name: 'afiliados.inativar',
          path: '/afiliados/:id/inativar',
          icon: 'fa fa-ban',
          requireAuth: true,
          component: () => import('./components/views/afiliados/Inativar'),
          props: true,
          menu: false,
        },

        {
          label: 'Novo',
          description: 'Novo Comerciante',
          parent: 'afiliados',
          path: '/afiliados/novo',
          name: 'afiliados.novo',
          icon: 'fa fa-plus',
          requireAuth: true,
          component: () => import('./components/views/afiliados/Novo'),
          permissions: {
            key: 'afiliados.novo',
            description: 'Utiliza Comerciantes',
          },
        },
        {
          label: 'Visualizar',
          description: 'Visualizar Comerciantes',
          parent: 'afiliados',
          path: '/afiliados',
          name: 'afiliados.visualizar',
          icon: 'fa fa-bars',
          requireAuth: true,
          component: () => import('./components/views/afiliados/Visualizar'),
          permissions: {
            key: 'afiliados.visualizar',
            description: 'Utiliza Afiliados',
          },
        },
        {
          label: 'Aniversariantes',
          description: 'Visualizar Comerciantes Aniversariantes',
          parent: 'afiliados',
          path: '/afiliados/aniversariantes',
          name: 'afiliados.aniversariantes',
          icon: 'fa fa-birthday-cake',
          requireAuth: true,
          component: () =>
            import('./components/views/afiliados/Aniversariantes'),
          permissions: {
            key: 'afiliados',
            description: 'Utiliza Afiliados',
          },
        },

        {
          label: 'Editar',
          description: 'Editar Comerciante',
          parent: 'afiliados',
          name: 'afiliados.edit',
          path: '/afiliados/:id',
          icon: 'fa fa-plus',
          requireAuth: true,
          component: () => import('./components/views/afiliados/Editar'),
          props: true,
          menu: false,
        },

        {
          label: 'Comerciantes',
          description: 'Visualizar Relatório de Comerciantes',
          name: 'relatorios.afiliado',
          path: '/relatorios/afiliados/:id',
          icon: 'fa fa-user',
          requireAuth: true,
          component: () => import('./components/views/relatorios/Afiliado'),
          props: true,
          menu: false,
          children: [
            {
              path: 'pagamentos',
              name: 'relatorios.afiliado.pagamentos',
              requireAuth: true,
              component: () =>
                import('./components/views/relatorios/afiliados/Pagamentos'),
              props: true,
            },
            {
              path: 'pendencias',
              name: 'relatorios.afiliado.pendencias',
              requireAuth: true,
              component: () =>
                import('./components/views/relatorios/afiliados/Pendencias'),
              props: true,
            },
            {
              path: 'informacoes',
              name: 'relatorios.afiliado.informacoes',
              requireAuth: true,
              component: () =>
                import('./components/views/relatorios/afiliados/Informacoes'),
              props: true,
            },
            {
              path: 'Documentos',
              name: 'relatorios.afiliado.documento',
              requireAuth: true,
              component: () =>
                import('./components/views/relatorios/afiliados/Documento'),
              props: true,
            },
          ],
        },
        {
          label: 'Boletos Finais',
          description: 'Gerenciamento de carnês e boletos',
          name: 'boletos',
          icon: 'fa fa-files-o',
          parent: 'colaboradores',
          path: '/colaboradores/boletos-finais',
          redirect: { name: 'boletos.doacoes' },
          requireAuth: true,
          component: () => import('./components/views/relatorios/Boletos'),
          children: [
            {
              name: 'boletos.doacoes',
              path: 'doacoes',
              requireAuth: true,
              props: { grupo: 'doacoes' },
              component: () =>
                import('./components/views/relatorios/boletos/Visualizar'),
            },
            {
              name: 'boletos.gerados',
              path: 'renovados',
              requireAuth: true,
              props: { grupo: 'doacoes' },
              component: () =>
                import('./components/views/relatorios/boletos/Gerados'),
            },
          ],
        },
        {
          label: 'Gerar Pix',
          description: 'Gerar Pix para Colaboradores',
          parent: 'colaboradores',
          path: '/colaboradores/:id/pix/novo',
          name: 'colaboradores.pix.novo',
          icon: 'fa fa-pix',
          requireAuth: true,
          component: () => import('./components/views/pix/Novo'),
          menu: false,
          permissions: {
            key: 'colaboradores.pix.novo',
            description: 'Gerar Pix (QR Code) para doações',
          },
        },
        {
          label: 'Gerar Boleto Avulso',
          description: 'Gerar Boletos para Colaboradores',
          parent: 'colaboradores',
          path: '/colaboradores/:id/boleto/novo/avulso',
          name: 'colaboradores.boleto.novo',
          icon: 'fa fa-file-o',
          requireAuth: true,
          component: () => import('./components/views/boleto/NovoAvulso'),
          props: {
            especie_id: 18,
            tipo_id: 1,
          },
          menu: false,
          permissions: {
            key: 'colaboradores.boleto.novo',
            description: 'Gerar boleto para doações',
          },
        },
        {
          label: 'Gerar Carnê',
          description: 'Gerar Carnê para Colaboradores',
          parent: 'colaboradores',
          path: '/colaboradores/:id/boleto/novo/carne',
          name: 'colaboradores.boleto.novo.carne',
          icon: 'fa fa-files-o',
          requireAuth: true,
          component: () => import('./components/views/boleto/NovoCarne'),
          props: {
            especie_id: 18,
            tipo_id: 1,
          },
          menu: false,
          permissions: {
            key: 'colaboradores.boleto.novo.carne',
            description: 'Gerar carnê para doações',
          },
        },
        {
          label: 'Registrar Doação',
          description: 'Registrar Doação',
          parent: 'colaboradores',
          name: 'colaboradores.doacao.registrar',
          path: '/colaboradores/:id/doacao',
          redirect: { name: 'colaboradores.doacao.registrar.balcao' },
          icon: 'fa fa-plus',
          requireAuth: true,
          component: () =>
            import('./components/views/colaboradores/RegistrarDoacao'),
          props: true,
          menu: false,
          permissions: {
            key: 'colaboradores.doacao.registrar',
            description: 'Registrar doações avulsas',
          },
          children: [
            {
              label: 'Balcão',
              description: 'Registrar Doações de Balcão',
              name: 'colaboradores.doacao.registrar.balcao',
              path: 'balcao/:tipo_pagamento?',
              icon: 'fa fa-at',
              requireAuth: true,
              component: () =>
                import(
                  './components/views/colaboradores/registrar-doacao/Balcao'
                ),
              props: true,
            },
            {
              label: 'Boletos',
              description: 'Registrar Doações de Boletos Pendentes',
              name: 'colaboradores.doacao.registrar.boleto',
              path: 'boletos',
              icon: 'fa fa-at',
              requireAuth: true,
              component: () =>
                import(
                  './components/views/colaboradores/registrar-doacao/Boletos'
                ),
              props: true,
            },
          ],
        },
        {
          label: 'Gerar Boleto Avulso',
          description: 'Gerar Boletos para Afiliados',
          parent: 'afiliados',
          path: '/afiliados/:id/boleto/novo/avulso',
          name: 'afiliados.boleto.novo',
          icon: 'fa fa-file-o',
          requireAuth: true,
          component: () => import('./components/views/boleto/NovoAvulso'),
          props: {
            especie_id: 99,
            tipo_id: 2,
          },
          menu: false,
          permissions: {
            key: 'afiliados',
            description: 'Gerar boleto para doações',
          },
        },
        {
          label: 'Gerar Carnê',
          description: 'Gerar Carnê para Afiliados',
          parent: 'afiliados',
          path: '/afiliados/:id/boleto/novo/carne',
          name: 'afiliados.boleto.novo.carne',
          icon: 'fa fa-files-o',
          requireAuth: true,
          component: () => import('./components/views/boleto/NovoCarne'),
          props: {
            especie_id: 99,
            tipo_id: 2,
          },
          menu: false,
          permissions: {
            key: 'afiliados.boleto.novo.carne',
            description: 'Gerar carnê para doações',
          },
        },
        {
          label: 'Registrar Pagamento',
          description: 'Registrar Pagamento',
          parent: 'afiliados',
          name: 'afliados.pagamento.registrar',
          path: '/afiliados/:id/pagamento',
          redirect: { name: 'afiliados.pagamento.registrar.balcao' },
          icon: 'fa fa-plus',
          requireAuth: true,
          component: () =>
            import('./components/views/afiliados/RegistrarPagamento'),
          props: true,
          menu: false,
          permissions: {
            key: 'afiliados.pagamento.registrar',
            description: 'Registrar doações avulsas',
          },
          children: [
            {
              label: 'Balcão',
              description: 'Registrar Pagamentos de Balcão',
              name: 'afiliados.pagamento.registrar.balcao',
              path: 'balcao/:tipo_pagamento?',
              icon: 'fa fa-at',
              requireAuth: true,
              component: () =>
                import(
                  './components/views/afiliados/registrar-pagamento/Balcao'
                ),
              props: true,
            },
            {
              label: 'Boletos',
              description: 'Registrar Pagamentos de Boletos Pendentes',
              name: 'afiliados.pagamento.registrar.boleto',
              path: 'boletos',
              icon: 'fa fa-at',
              requireAuth: true,
              component: () =>
                import(
                  './components/views/afiliados/registrar-pagamento/Boletos'
                ),
              props: true,
            },
          ],
        },
        {
          label: 'Boleto Online',
          description: 'Boleto Online',
          path: '/boleto',
          name: 'boleto',
          parent: 'colaboradores',
          icon: 'fa fa-barcode',
        },
        {
          label: 'Avulso',
          description: 'Gerar Boletos Avulsos',
          parent: 'boleto',
          path: '/boleto/novo/avulso',
          name: 'boleto.novo',
          icon: 'fa fa-file-o',
          requireAuth: true,
          component: () => import('./components/views/boleto/NovoAvulso'),
          props: {
            especie_id: 18,
            tipo_id: 1,
          },
          permissions: {
            key: 'boleto.novo',
            description: 'Gerar doações',
          },
        },
        {
          label: 'Carnê',
          description: 'Gerar Carnê',
          parent: 'boleto',
          path: '/boleto/novo/carne',
          name: 'boleto.novo.carne',
          icon: 'fa fa-files-o',
          requireAuth: true,
          component: () => import('./components/views/boleto/NovoCarne'),
          props: {
            especie_id: 18,
            tipo_id: 1,
          },
          permissions: {
            key: 'boleto.novo.carne',
            description: 'Gerar doações',
          },
        },
        {
          label: 'Boleto Online',
          description: 'Boleto Online',
          path: 'afiliado/boleto',
          name: 'afiliado.boleto',
          parent: 'afiliados',
          icon: 'fa fa-barcode',
        },
        {
          label: 'Avulso',
          description: 'Gerar Boletos Avulsos',
          parent: 'afiliado.boleto',
          path: '/afiliados/boleto/novo/avulso',
          name: 'afiliado.boleto.novo',
          icon: 'fa fa-file-o',
          requireAuth: true,
          component: () => import('./components/views/boleto/NovoAvulso'),
          props: {
            especie_id: 99,
            tipo_id: 2,
          },
          permissions: {
            key: 'afiliado.boleto.novo',
            description: 'Gerar doações',
          },
        },
        {
          label: 'Carnê',
          description: 'Gerar Carnê',
          parent: 'afiliado.boleto',
          path: '/afiliado/boleto/novo/carne',
          name: 'afiliado.boleto.novo.carne',
          icon: 'fa fa-files-o',
          requireAuth: true,
          component: () => import('./components/views/boleto/NovoCarne'),
          props: {
            especie_id: 99,
            tipo_id: 2,
          },
          permissions: {
            key: 'afiliado.boleto.novo.carne',
            description: 'Gerar doações',
          },
        },
        {
          label: 'Doação Espontânea',
          description: 'Doação Espontânea',
          path: '/colaborador/espontaneo',
          name: 'colaborador.espontanea',
          icon: 'fa fa-user',
          parent: 'colaboradores',
          requireAuth: true,
          component: () =>
            import('./components/views/colaboradores/Espontanea'),
        },
        {
          label: 'Eventos',
          description: 'Eventos',
          path: '/eventos::',
          name: 'eventos',
          icon: 'fa fa-calendar-check-o',
        },
        {
          label: 'Baixar relatórios de participantes',
          description: 'Permitir baixar relatórios de participantes',
          menu: false,
          name: 'relatorios.eventos.participantes.download',
          path: '/relatorios/eventos/participantes/download:',
          component: false,
          parent: 'eventos',
          icon: '',
        },
        {
          label: 'Novo',
          description: 'Criar novo evento',
          name: 'eventos.novo',
          path: '/eventos/novo',
          parent: 'eventos',
          icon: 'fa fa-plus',
          component: () => import('./components/views/eventos/Novo'),
        },
        {
          label: 'Visualizar',
          description: 'Visualizar eventos',
          name: 'eventos.visualizar',
          path: '/eventos',
          parent: 'eventos',
          icon: 'fa fa-bars',
          component: () => import('./components/views/eventos/Visualizar'),
        },
        {
          label: 'Editar evento',
          description: 'Editar evento',
          name: 'eventos.editar',
          path: '/eventos/:id(\\d+)',
          parent: 'eventos',
          icon: 'fa fa-bars',
          menu: false,
          props: true,
          component: () => import('./components/views/eventos/Editar'),
        },

        {
          label: 'Participantes evento',
          description: 'Lista de parcipantes de eventos',
          path: '/eventos/:id/participantes',
          parent: 'eventos',
          name: 'eventos.participantes',
          icon: 'fa fa-users',
          menu: false,
          props: true,
          component: () => import('./components/views/eventos/Participantes'),
        },
        {
          label: 'Novo participante evento',
          description: 'Novo participante evento',
          path: '/eventos/:id/participantes/novo/:ref_id(\\d+)?',
          parent: 'eventos',
          name: 'eventos.participantes.novo',
          icon: 'fa fa-plus',
          menu: false,
          props: true,
          component: () =>
            import('./components/views/eventos/NovoParticipante'),
        },

        {
          label: 'Sorteios',
          description: 'Sorteios',
          path: '/sorteios::',
          name: 'sorteios',
          icon: 'fa fa-cube',
        },

        {
          label: 'Novo',
          description: 'Criar novo sorteio',
          name: 'sorteios.novo',
          path: '/sorteios/novo',
          parent: 'sorteios',
          icon: 'fa fa-plus',
          component: () => import('./components/views/sorteio/Novo'),
        },
        {
          label: 'Visualizar',
          description: 'Visualizar sorteios',
          name: 'sorteios.visualizar',
          path: '/sorteios',
          parent: 'sorteios',
          icon: 'fa fa-list',
          component: () => import('./components/views/sorteio/Visualizar'),
        },

        {
          label: 'Participantes evento',
          description: 'Lista de parcipantes de sorteio',
          path: '/sorteio/:id/participantes',
          parent: 'sorteios',
          name: 'sorteio.participantes',
          icon: 'fa fa-users',
          menu: false,
          props: true,
          component: () => import('./components/views/sorteio/Participantes'),
        },

        {
          label: 'Campanhas',
          description: 'Campanhas',
          path: '/campanhas::',
          name: 'campanhas',
          icon: 'fa fa-bullhorn',
        },
        {
          label: 'Nova campanha',
          description: 'Criar nova campanha',
          name: 'campanhas.nova',
          path: '/campanhas/nova',
          parent: 'campanhas',
          icon: 'fa fa-plus',
          component: () => import('./components/views/campanhas/Nova'),
        },
        {
          label: 'Nova campanha (Nova Versão)',
          description: 'Criar nova campanha',
          name: 'campanhas.nova.v2',
          path: '/campanhas/v2/nova',
          parent: 'campanhas',
          icon: 'fa fa-plus',
          component: () => import('./components/views/campanhas/NovaV2'),
        },
        {
          label: 'Visualizar Campanhas',
          description: 'Visualizar campanha',
          name: 'campanhas.visualizar',
          path: '/campanhas/visualizar',
          parent: 'campanhas',
          icon: 'fa fa-bars',
          component: () => import('./components/views/campanhas/Visualizar'),
        },
        {
          label: 'Editar Campanhas',
          description: 'Editar campanha',
          name: 'campanhas.editar',
          path: '/campanhas/editar/:id',
          parent: 'campanhas',
          menu: false,
          component: () => import('./components/views/campanhas/_editar'),
        },
        {
          label: 'Visualizar Grupo',
          description: 'Visualizar Grupo',
          name: 'campanhas.visualizar.grupo',
          path: '/campanhas/visualizar/grupo',
          parent: 'campanhas',
          icon: 'fa fa-bars',
          component: () =>
            import('./components/views/campanhas/VisualizarGrupo'),
        },
        {
          label: 'Larçamento manual',
          description: 'Lançamento manual',
          name: 'campanhas.lancamento_manual',
          path: '/campanhas/lancamento/manual',
          parent: 'campanhas',
          icon: 'fa fa-money',
          component: () => import('./components/views/campanhas/DoacaoAvulsa'),
        },
        {
          label: 'Publicar Campanhas',
          description: 'Publicar Campanhas',
          parent: 'campanhas',
          name: 'campanhas.publicar',
          path: '/campanhas/publicar:',
          menu: false,
          component: false,
        },
        // {
        //     label: 'Publicar Campanhas',
        //     path: '/publicar',
        //     name: 'publicar.campanhas',
        //     menu:false,
        //     component: false,
        // },
        {
          label: 'Templates',
          description: 'Templates',
          path: '/templates::',
          name: 'templates',
          icon: 'fa fa-file-image-o',
        },
        {
          label: 'Email',
          description: 'Templates de Email',
          path: '/templates/email::',
          name: 'templates.email',
          parent: 'templates',
          icon: 'fa fa-envelope',
        },
        {
          label: 'Novo',
          description: 'Criar novo templates de email',
          name: 'templates.email.novo',
          path: '/templates/email/novo',
          parent: 'templates.email',
          menu: false,
          icon: 'fa fa-plus',
          component: () => import('./components/views/templates/email/Novo'),
          permissions: {
            key: 'templates.email.novo',
            description: 'Cadastrar template de email',
          },
        },
        {
          label: 'Padrão',
          description: 'Template do grupo padrão',
          name: 'templates.email.padrao',
          path: '/templates/email/padrao',
          parent: 'templates.email',
          icon: 'fa fa-globe',
          props: { grupo_id: 1 },
          component: () =>
            import('./components/views/templates/email/Visualizar'),
        },
        {
          label: 'WhatsApp',
          description: 'Templates de WhatsApp',
          path: '/templates/whatsapp::',
          name: 'templates.whatsapp',
          parent: 'templates',
          icon: 'fa fa-whatsapp',
          component: () =>
            import('./components/views/templates/whatsapp/Visualizar'),
        },
        {
          label: 'Visualizar',
          description: 'Visualizar templates de WhatsApp',
          path: '/templates/whatsapp',
          name: 'templates.whatsapp.visualizar',
          parent: 'templates.whatsapp',
          icon: 'fa fa-bars',
          component: () =>
            import('./components/views/templates/whatsapp/Visualizar'),
          permissions: {
            key: 'templates.whatsapp.visualizar',
            description: 'Visualizar templates de WhatsApp',
          },
        },
        {
          label: 'Novo',
          description: 'Novo template de whatsapp',
          path: '/templates/whatsapp/novo',
          name: 'templates.whatsapp.novo',
          parent: 'templates.whatsapp',
          menu: false,
          icon: 'fa fa-whatsapp',
          component: () => import('./components/views/templates/whatsapp/Novo'),
          permissions: {
            key: 'templates.whatsapp.novo',
            description: 'Novo template de WhatsApp',
          },
        },
        {
          label: 'Editar',
          description: 'Editar template de whatsapp',
          path: '/templates/whatsapp/:id(\\d+)',
          name: 'templates.whatsapp.editar',
          parent: 'templates.whatsapp',
          icon: 'fa fa-whatsapp',
          menu: false,
          component: () =>
            import('./components/views/templates/whatsapp/Editar'),
          permissions: {
            key: 'templates.whatsapp.editar',
            description: 'Editar template de whatsapp',
          },
        },
        {
          label: 'Campanha',
          description: 'Template do grupo campanha',
          name: 'templates.email.campanha',
          path: '/templates/email/campanha',
          parent: 'templates.email',
          icon: 'fa fa-bullhorn',
          props: { grupo_id: 2 },
          component: () =>
            import('./components/views/templates/email/Visualizar'),
        },
        {
          label: 'Evento',
          description: 'Template do grupo eventos',
          name: 'templates.email.eventos',
          path: '/templates/email/eventos',
          parent: 'templates.email',
          icon: 'fa fa-calendar-check-o',
          props: { grupo_id: 3 },
          component: () =>
            import('./components/views/templates/email/Visualizar'),
        },
        {
          label: 'Leads',
          description: 'Template do grupo leads',
          name: 'templates.email.leads',
          path: '/templates/email/leads',
          parent: 'templates.email',
          icon: 'fa fa-user',
          props: { grupo_id: 4 },
          component: () =>
            import('./components/views/templates/email/Visualizar'),
        },

        {
          label: 'Loja',
          description: 'Template do grupo loja',
          name: 'templates.email.loja',
          path: '/templates/email/loja',
          parent: 'templates.email',
          icon: 'fa fa-calendar-check-o',
          props: { grupo_id: 5 },
          component: () =>
            import('./components/views/templates/email/Visualizar'),
        },

        {
          label: 'Editar',
          description: 'Editar template de email',
          name: 'templates.email.editar',
          path: '/templates/email/:id(\\d+)/:grupo_id(\\d+)',
          menu: false,
          icon: 'fa fa-edit',
          component: () => import('./components/views/templates/email/Editar'),
          permissions: {
            key: 'templates.email.editar',
            description: 'Editar template de email',
          },
        },
        {
          label: 'Visualizar',
          description: 'Visualizar templates de email',
          name: 'templates.email.visualizar',
          path: '/templates/email',
          parent: 'templates.email',
          icon: 'fa fa-bars',
          menu: false,
          component: () =>
            import('./components/views/templates/email/Visualizar'),
        },
        {
          label: 'Boleto',
          description: 'Templates de Boletos',
          path: '/templates/boletos::',
          name: 'templates.boletos',
          parent: 'templates',
          icon: 'fa fa-barcode',
        },
        {
          label: 'Visualizar',
          description: 'Visualizar templates de boletos',
          name: 'templates.boletos.visualizar',
          path: '/templates/boletos',
          redirect: { name: 'templates.boletos.visualizar.avulso' },
          parent: 'templates.boletos',
          icon: 'fa fa-bars',
          component: () =>
            import('./components/views/templates/boletos/Visualizar'),
          children: [
            {
              label: 'Avulso',
              description: 'Visualizar templates de boleto avulso',
              name: 'templates.boletos.visualizar.avulso',
              path: 'avulso',
              parent: 'templates.boletos',
              icon: 'fa fa-file',
              component: () =>
                import(
                  './components/views/templates/boletos/visualizar/Avulso'
                ),
            },
          ],
        },
        {
          label: 'Recibo',
          description: 'Templates de Recibo',
          path: '/templates/recibo',
          name: 'templates.recibo',
          parent: 'templates',
          requireAuth: true,
          icon: 'fa fa-file-word-o',
          component: () =>
            import('./components/views/templates/recibos/Visualizar'),
        },

        {
          label: 'Plugins',
          description: 'Plugins',
          path: '/plugins',
          name: 'plugins',
          icon: 'fa fa-plug',
        },
        {
          label: 'Eventos',
          description: 'Visualizar Plugin de Eventos',
          parent: 'plugins',
          name: 'plugins.eventos',
          path: '/plugins/eventos',
          icon: 'fa fa-tag',
          requireAuth: true,
          component: () => import('./components/views/plugins/Eventos'),
        },

        {
          label: 'Marketing',
          description: 'Marketing',
          name: 'marketing',
          path: '/marketing_',
          icon: 'fa fa-bullhorn',
        },
        {
          label: 'Artes',
          description: 'Artes',
          parent: 'marketing',
          name: 'marketing.artes',
          path: '/marketing/artes_',
          icon: 'fa fa-picture-o',
        },
        {
          label: 'Novo Upload',
          description: 'Upload de Artes',
          parent: 'marketing.artes',
          name: 'marketing.artes.novo',
          path: '/marketing/artes/novo',
          icon: 'fa fa-upload',
          requireAuth: true,
          component: () => import('./components/views/marketing/NovoUpload'),
        },
        {
          label: 'Visualizar',
          description: 'Visualizar Artes',
          parent: 'marketing.artes',
          name: 'marketing.artes.visualizar',
          path: '/marketing/artes/visualizar',
          icon: 'fa fa-bars',
          requireAuth: true,
          component: () => import('./components/views/marketing/Visualizar'),
        },

        // {
        //   label: 'Suporte',
        //   description: 'Suporte',
        //   name: 'suporte',
        //   path: '/suporte_',
        //   icon: 'fa fa-ticket',
        // },

        {
          label: 'Novo',
          description: 'Novo chamado',
          parent: 'suporte',
          name: 'suporte.novo',
          path: '/suporte/novo',
          icon: 'fa fa-plus',
          requireAuth: true,
          component: () => import('./components/views/suporte/Novo'),
        },

        {
          label: 'Visualizar',
          description: 'Visualizar chamado',
          parent: 'suporte',
          name: 'suporte.visualizar',
          path: '/suporte',
          icon: 'fa fa-list',
          requireAuth: true,
          component: () => import('./components/views/suporte/Visualizar.vue'),
        },
        {
          label: 'Editar',
          description: 'Editar chamado',
          parent: 'suporte',
          name: 'suporte.editar',
          path: '/suporte/:id(\\d+)',
          icon: 'fa fa-ticket',
          requireAuth: true,
          component: () => import('./components/views/suporte/Editar.vue'),
          menu: false,
          props: true,
        },

        {
          forceAllow: true,
          path: '/erro/:id',
          name: 'error',
          requireAuth: true,
          component: () => import('./components/views/Erro'),
          props: true,
          menu: false,
        },
      ],
    },
  ],
}
